import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ProductionChart from "components/ProductionChart";
import { Mode } from "components/ProductionChart/ProductionChart";
import config from "config";
import { Production } from "types";
import { aggregate } from "utils/production-utils";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import InfoCardErrorView from "components/InfoCardErrorView";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";

interface DailyProductionProps {
  plantId?: string;
  sectionId?: string;
}

export const DailyProductionCard: React.FC<DailyProductionProps> = (props) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();
  const [clientError, setClientError] = useState<string | undefined>(undefined);

  const [aggregatedData, setAggregatedData] = useState<Production | undefined>(
    undefined
  );

  const [lastQueriedTimestamp, setLastQueriedTimeStamp] = useState<
    Date | undefined
  >(undefined);

  const { data, isFetching, error } = useScadaPlusQuery<Production>(
    `productionPast24Hours-${props.plantId}-${props.sectionId}`,
    () => {
      const aggregate = "aggregateBy=HourLastDay";
      if (props.sectionId) {
        return `${config.api.url}/${config.api.production}?sectorId=${props.sectionId}&${aggregate}`;
      }
      if (props.plantId) {
        return `${config.api.url}/${config.api.production}?windpowerplantId=${props.plantId}&${aggregate}`;
      }
      return `${config.api.url}/${config.api.production}?allWindPowerPlants=true&${aggregate}`;
    },
    60 * 1000,
    (errorMessage) => {
      if (errorMessage.status) {
        setClientError(
          `Server response status: ${errorMessage.status} - ${errorMessage.text}`
        );
      } else {
        setClientError(errorMessage.text);
      }
    },
    () => {
      setLastQueriedTimeStamp(new Date());
    }
  );

  useEffect(() => {
    if (data) {
      setAggregatedData(aggregate(data));
    }
  }, [data]);

  return error || clientError ? (
    <InfoCardErrorView
      lastQueriedTimestamp={lastQueriedTimestamp}
      theme={theme}
      // TODO, hvordan typedefinere error i UseQueryResult slik at komponent vet returnert feil-type
      error={typeof error === "string" ? error : ""}
    />
  ) : (
    <ProductionChart
      data={aggregatedData}
      keyValue={
        aggregatedData && aggregatedData.data
          ? aggregatedData.data[0].actual / 1000
          : undefined
      }
      mode={Mode.Hour}
      fullWidth={true}
      isLoading={isFetching}
      title={formatMessage({
        id: "overview.24Hours",
        defaultMessage: "Day (24 Hours MW)",
      })}
      getTooltipText={(actual: number, forecast: number, timestamp: number) => {
        return [
          {
            key: formatMessage({
              id: "pu.Produced",
              defaultMessage: "Produced: ",
            }),
            value: `${actual.toFixed(2).toString()} ${formatMessage({
              id: "pu.MWh",
              defaultMessage: "MWh",
            })}`,
          },
          {
            key: formatMessage({
              id: "pu.EstimatedTotal",
              defaultMessage: "Estimated total: ",
            }),
            value: forecast.toFixed(2).toString(),
          },
        ];
      }}
    />
  );
};
