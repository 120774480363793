import React, { useEffect, useState } from "react";
import InfoCard from "../../../components/InfoCard";
import {
  SensorData,
  useSensorSubscription,
} from "../../../hooks/useSubscription";
import { useTagsContext } from "../../../hooks/useTags";
import { useIntl } from "react-intl";
import styled from "styled-components";
import CardLabel from "components/CardLabel";
import NumberValueFormatter from "components/NumberValueFormatter";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { MeasurementUnitSymbolConstants } from "utils/utils";

const MotionAndPositionContainer = styled.div`
  display: grid;
  padding-top: ${(props) => props.theme.spacing.light};
  height: 85%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  grid-row-gap: ${(props) => props.theme.spacing.medium};
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

type CardProps = {
  componentUnitId: string;
};

export const MotionAndPositionCard: React.FC<CardProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [roll, setRoll] = useState<SensorData | null>(null);
  const [yaw, setYaw] = useState<SensorData | null>(null);
  const [pitch, setPitch] = useState<SensorData | null>(null);
  const [surge, setSurge] = useState<SensorData | null>(null);
  const [sway, setSway] = useState<SensorData | null>(null);
  const [heave, setHeave] = useState<SensorData | null>(null);
  const { theme } = useScadaPlusTheme();

  const { sensorData: mruData } = useSensorSubscription({
    filters: [
      {
        id: tags.WTOWHeading,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWPitch,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWRoll,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWSurge,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWSway,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWHeave,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (mruData?.id) {
      case tags.WTOWRoll:
        setRoll(mruData);
        break;
      case tags.WTOWHeading:
        setYaw(mruData);
        break;
      case tags.WTOWPitch:
        setPitch(mruData);
        break;
      case tags.WTOWSurge:
        setSurge(mruData);
        break;
      case tags.WTOWSway:
        setSway(mruData);
        break;
      case tags.WTOWHeave:
        setHeave(mruData);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heave, mruData, pitch, roll, surge, sway, yaw]);

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.MotionAndPosition",
        defaultMessage: "Missing text",
      })}
      style={{ height: "100%" }}
    >
      <MotionAndPositionContainer>
        <DataContainer>
          <CardLabel>Roll</CardLabel>
          <NumberValueFormatter
            value={Number(roll?.value)}
            precision={1}
            loaderPadding={theme.spacing.light}
            measurementType={MeasurementUnitSymbolConstants.degree}
          />
        </DataContainer>

        <DataContainer>
          <CardLabel>Pitch</CardLabel>
          <NumberValueFormatter
            value={Number(pitch?.value)}
            precision={1}
            loaderPadding={theme.spacing.light}
            measurementType={MeasurementUnitSymbolConstants.degree}
          />
        </DataContainer>

        <DataContainer>
          <CardLabel>Yaw</CardLabel>
          <NumberValueFormatter
            value={Number(yaw?.value)}
            precision={1}
            loaderPadding={theme.spacing.light}
            measurementType={MeasurementUnitSymbolConstants.degree}
          />
        </DataContainer>

        <DataContainer>
          <CardLabel>Surge</CardLabel>
          <NumberValueFormatter
            value={Number(surge?.value)}
            precision={1}
            measurementType={MeasurementUnitSymbolConstants.meter}
            loaderPadding={theme.spacing.light}
          />
        </DataContainer>

        <DataContainer>
          <CardLabel>Sway</CardLabel>
          <NumberValueFormatter
            value={Number(sway?.value)}
            precision={1}
            measurementType={MeasurementUnitSymbolConstants.meter}
            loaderPadding={theme.spacing.light}
          />
        </DataContainer>

        <DataContainer>
          <CardLabel>Heave</CardLabel>
          <NumberValueFormatter
            value={Number(heave?.value)}
            measurementType={MeasurementUnitSymbolConstants.meter}
            precision={1}
            loaderPadding={theme.spacing.light}
          />
        </DataContainer>
      </MotionAndPositionContainer>
    </InfoCard>
  );
};
