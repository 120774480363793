import React from "react";
import ReactDOM from "react-dom";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "leaflet/dist/leaflet.css";
import "react-toastify/dist/ReactToastify.css";
import { ScadaPlusPermissionsProvider } from "./providers/ScadaPlusPermissionsProvider";

import config from "./config";

const configuration: Configuration = {
  auth: {
    clientId: config.clientId,
    authority: `https://login.microsoftonline.com/${config.tenantId}`,
    redirectUri: config.redirectUri,
  },
};

const instance = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={instance}>
      <ScadaPlusPermissionsProvider>
        <App />
      </ScadaPlusPermissionsProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
