import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import { useLocalDateTimeFormat } from "hooks/useDateTimeFormat";

//https://bl.ocks.org/valex/9b27aa1644bd80e9f306633f56a8f09b
interface RealTimeLineChartChartProps {
  data: Datum | null;
  width?: string;
  height?: string;
  max: number;
  min: number;
  duration: number;
  historicalData?: Array<Datum>;
}

export type Datum = {
  time: Date;
  value: number;
};

type ContainerProps = {
  padding: string;
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: ${(props: ContainerProps) => {
    return props.padding;
  }};
`;

const maxLength = 30;

export const RealTimeLineChart = (props: RealTimeLineChartChartProps) => {
  const { theme } = useScadaPlusTheme();
  const dateTimeLocal = useLocalDateTimeFormat();
  const d3Container = useRef<SVGSVGElement>(null);
  const [data, setData] = useState<Array<Datum>>([]);

  useEffect(() => {
    if (props.data) {
      setData((state) => {
        const dataList = [...state];
        dataList.push(props.data!);
        if (dataList.length >= maxLength) {
          dataList.shift();
        }

        return dataList;
      });
    }
  }, [props.data]);

  useEffect(() => {
    setData(props.historicalData ?? []); //Mount only
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data.length > 0 && d3Container.current) {
      const svg = d3.select(d3Container.current);
      const { width, height } = d3Container.current.getBoundingClientRect();
      svg.attr("viewBox", `0 0 ${width} ${height}`);

      const transition = d3
        .transition()
        .duration(props.duration)
        .ease(d3.easeLinear);

      const xScale = d3.scaleTime().rangeRound([0, width]);

      const zScale = d3.scaleOrdinal([theme.colors.mainColor.primary]);

      const dataset = ["value"].map((c) => {
        return {
          label: c,
          values: data.map(function (d: Datum) {
            return { time: d.time, value: d.value };
          }),
        };
      });
      const min = d3.min(dataset, function (c) {
        return d3.min(c.values, function (d) {
          return d.time;
        });
      });
      const max = d3.max(dataset, function (c) {
        return d3.max(c.values, function (d) {
          return d.time;
        });
      });

      const bisectDate = d3.bisector(function (d: Datum) {
        return d.time;
      }).left;
      const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([props.min, props.max]);

      //Add a second to not get the last point at the edge
      const maxAjdusted = new Date(max!.getTime() + 1000);
      xScale.domain([min!, maxAjdusted]);

      const line = d3
        .line<Datum>()

        .curve(d3.curveMonotoneX)
        .x((d: Datum) => {
          return xScale(d.time)!;
        })
        .y((d: Datum) => {
          return yScale(d.value)!;
        });

      const svgRoot = svg.selectAll("svg").data([data]);
      const gEnter = svgRoot
        .enter()
        .append("svg")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .append("g");

      gEnter
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      gEnter
        .append("g")
        .attr("class", "lines")
        .attr("clip-path", "url(#clip)")
        .selectAll(".data")
        .data(data)
        .enter()
        .append("path")
        .attr("class", "data");

      const g = svgRoot.select("g");

      g.select("defs clipPath rect")
        .transition(transition as any)
        .attr("width", width)
        .attr("height", height);

      g.selectAll("g path.data")
        .data(dataset)
        .style("stroke", function (d: any) {
          return zScale(d.label);
        })
        .style("stroke-width", 2)
        .style("fill", "none")
        .transition()
        .duration(props.duration)
        .ease(d3.easeLinear)
        .on("start", (a, b, c) => {
          d3.select(c[b])!
            .attr("d", function (d: any) {
              return line(d.values);
            })
            .attr("data-testid", "line-data")
            .attr("transform", null);
        });
      // Tooltip
      svg.select(".focus").remove();
      svg.select(".overlay").remove();
      svg.select(".tooltip").remove();

      const focus = svg
        .append("g")
        .attr("class", "focus")
        .append("circle")
        .style("fill", theme.colors.mainColor.primary)
        .attr("r", 4)
        .style("display", "none");

      const tooltip = svg
        .append("g")
        .attr("class", "tooltip")
        .style("display", "none");

      tooltip
        .append("rect")
        .attr("width", 60)
        .attr("height", 30)
        .attr("rx", theme.borderRadius.light)
        .attr("fill", theme.colors.componentBackgroundColor.primary);

      tooltip
        .append("text")
        .attr("class", "textKey")
        .attr("y", 20)
        .attr("x", 30)
        .style("text-anchor", "middle")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      tooltip
        .append("text")
        .attr("class", "textValue")
        .attr("y", 20)
        .attr("x", 60)
        .attr("font-weight", "600")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      svg
        .append("g")
        .attr("class", "overlay")
        .append("rect")
        .attr("fill", "none")
        .attr("width", width)
        .attr("height", height)
        .on("mouseover", function () {
          if (data) {
            focus.style("display", null);
            tooltip.style("display", null);
          }
        })
        .on("mouseout", function () {
          if (data) {
            focus.style("display", "none");
            tooltip.style("display", "none");
          }
        })
        .style("pointer-events", "all")
        .on("mousemove", (a, b, c) => {
          if (data) {
            const x0 = xScale.invert(d3.mouse(c[b]!)[0]);
            const i = bisectDate(data, x0, 1);
            const d: Datum = data[i];
            if (d) {
              let x = xScale(d.time)!;
              let y = yScale(d.value)!;
              focus.attr("cx", x).attr("cy", y);

              if (x - 100 < width) {
                x += 30;
              }

              if (x + 120 > width) {
                x -= 190;
              }

              if (y + 25 > height) {
                y -= 35;
              }

              tooltip
                .select(".textKey")
                .attr("x", x)
                .attr("y", y + 25);

              tooltip
                .select(".textValue")
                .attr("x", x + 60)
                .attr("y", y + 25);

              tooltip
                .select(".textKey")
                .text(`${dateTimeLocal.format(d.time)}`);
              tooltip.select(".textValue").text(`${d.value.toFixed(2)} MW`);
            }
          }
        });
    }
  }, [data, props.min, props.max, props.duration, theme, dateTimeLocal]);
  return (
    <Container padding={theme.spacing.heavy}>
      <svg
        ref={d3Container}
        preserveAspectRatio="none"
        width={"100%"}
        height={"80%"}
      ></svg>
    </Container>
  );
};
