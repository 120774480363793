import "styled-components";
import { DefaultTheme } from "styled-components";
import { Tags } from "./types";
import { propertyOf } from "./utils/utils";
import { isUsingMobileDevice } from "./utils/device-utils";
import { BaseTheme, ColorPalette, Colors, Theme } from "theme-types";
declare module "styled-components" { 
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export enum ThemeNames {
  Light = "light",
  Dark = "dark",
}

function hslaValues(hsla: string): HSLA {
  const sep = hsla.indexOf(",") > -1 ? "," : " ";
  const result = hsla.substr(5).split(")")[0].split(sep);

  if (result.indexOf("/") > -1) result.splice(3, 1);

  const returnObj = new HSLA();
  returnObj.h = result[0];
  returnObj.s = Number(result[1].substr(0, result[1].length - 1)) / 100;
  returnObj.l = Number(result[2].substr(0, result[2].length - 1)) / 100;
  returnObj.a = result[3];
  return returnObj;
}

const getTemperatureColors = (colors: Colors) => {
  const temperatureColors = new Map<string, string>();
  temperatureColors.set(
    propertyOf<Tags>("WTRMGbxOilTmp"),
    colors.mainColor.primary
  );
  temperatureColors.set(
    propertyOf<Tags>("WNAClntnTmp"),
    colors.mainColor.secondary
  );
  temperatureColors.set(
    propertyOf<Tags>("WMETEnvTmp"),
    colors.mainColor.tertiary
  );
  temperatureColors.set(
    propertyOf<Tags>("WTURRotTmp"),
    colors.mainColor.quaternary
  );
  temperatureColors.set(
    propertyOf<Tags>("WTURSttTmp"),
    colors.mainColor.quinary
  );
  return temperatureColors;
};

function hslaString(hsla: HSLA) {
  return (
    "hsla(" +
    hsla.h +
    "," +
    hsla.s * 100 +
    "%," +
    hsla.l * 100 +
    "%," +
    hsla.a +
    ")"
  );
}

export class HSLA {
  h = "";
  s = 0;
  l = 0;
  a = "";

  toString = () => hslaString(this);
}

export class ColorSet {
  primary = "";
  secondary = "";
  tertiary = "";
  quaternary = "";
  quinary = "";
  background = "";
  half = "";

  constructor(primaryColor: string) {
    this.primary = primaryColor;
    this.secondary = this.changeAlpha(this.primary, ".6");
    this.tertiary = this.changeAlpha(this.primary, ".6");
    this.quaternary = this.changeAlpha(this.primary, ".3");
    this.quinary = this.changeAlpha(this.primary, ".2");
    this.background = this.changeAlpha(this.primary, ".4");
    this.half = this.changeAlpha(this.primary, ".5");
  }

  private changeAlpha(hslaString: string, newAlpha: string): string {
    const hsla = hslaValues(hslaString);
    hsla.a = newAlpha;
    return hsla.toString();
  }
}

export class Shadow {
  color = "";
  rect = "";
  boxShadow= ``;

  constructor(rect: string, color: string) {
    this.rect = rect;
    this.color = color;
    this.boxShadow = `${this.rect} ${this.color}`;
  }
}

const colorPalette : ColorPalette = {
  darkBodyBackground: "hsla(192, 56%, 4%, 1)",
  darkTopMenu: "hsla(196, 54%, 15%, 1)",
  dark1:"hsla(191, 55%, 6%, 1)",
  dark2: "hsla(191, 19%, 45%, 1)",
  dark2BlueLight: "hsla(213, 63%, 18%, 1)",
  dark2BlueLighter: "hsla(212, 62%, 24%, 1)",
  dark3: "hsla(191, 53%, 40%, 1)",
  dark4: "hsla(191, 30%, 33%, 1)",
  dark5: "hsla(192, 37%, 23%, 1)",
  dark6: "hsla(196, 54%, 15%, 1)",
  darkAlarm1: "hsla(11, 76%, 42%, 1)",
  darkAlarm1Lighter: "hsla(7, 100%, 59%, 1)",
  darkAlarm2: "hsla(58, 58%, 53%, 1)",
  darkAlarm2Lighter: "hsla(58, 89%, 61%, 1)",
  darkAlarm3: "hsla(191, 30%, 33%, 1)", 
  darkAlarm3Lighter: "hsla(191, 30%, 49%, 1)",
  darkAlarm4: "hsla(196, 54%, 15%, 1)",
  darkAlarm4Lighter:"hsla(196,54%,31%,1)",
  darkAttention1: "hsla(29, 96%, 54%, 1)",
  darkAttention1Lighter: "hsla(31, 100%, 60%, 1)",
  darkAttention2:"hsla(122, 53%, 40%, 1)",
  darkAttention3:"hsla(11, 0%, 36%, 1)",
  darkGray1: "hsla(210, 27%, 86%, 1)",
  darkGray2:"hsla(210, 27%, 86%, 0.8)",
  darkGray3: "hsla(210, 27%, 86%, 0.5)",
  
  lightBodyBackground: "hsla(167, 22%, 92%, 1)",
  lightTopMenu: "hsla(167, 8%, 79%, 1)",
  light1: "hsla(0, 0%, 99%, 1)",
  light2: "hsla(166, 23%, 47%, 1)",
  light2BlueLight: "hsla(167, 20%, 65%, 1)",
  light2BlueLighter: "hsla(165, 21%, 74%, 1)",
  light3: "hsla(167, 22%, 16%, 1)",
  light4: "hsla(166, 23%, 47%, 0.6)",
  light5: "hsla(166, 23%, 47%, 0.4)",
  light6: "hsla(166, 20%, 78%, 1)",
  lightAlarm1: "hsla(0, 79%, 63%, 1)",
  lightAlarm1Lighter: "hsla(7, 100%, 59%, 1)",
  lightAlarm2: "hsla(46, 89%, 68%, 1)",
  lightAlarm2Lighter: "hsla(58, 89%, 61%, 1)",
  lightAlarm3: "hsla(167, 20%, 65%, 1)",
  lightAlarm3Lighter: "hsla(191, 30%, 49%, 1)",
  lightAlarm4: "hsla(166, 20%, 78%, 1)",
  lightAlarm4Lighter:"hsla(166,20%,90%,1)",
  lightAttention1: "hsla(28, 87%, 62%, 1)",
  lightAttention1Lighter: "hsla(30, 91%, 70%, 1)",
  lightAttention2: "hsla(116, 43%, 55%, 1)",
  lightAttention3: "hsla(167, 22%, 16%, 0.3)",
  lightGray1: "hsla(167, 22%, 16%, 1)",
  lightGray2: "hsla(167, 22%, 16%, 0.5)",
  lightGray3: "hsla(167, 22%, 16%, 0.3)",

  origoLogoGrey1: "hsla(0, 0.45%, 43.73%, 1)",
  origoLogoGrey2: "hsla(0, 0%, 52.94%, 1)",
  origoLogoWhite: "hsla(0, 0%, 100%, 1)",

  origoLogoDarkBlue: "hsla(205, 78%, 16%, 1)",
  origoLogoDarkBlueHover: "hsl(205, 78%, 38%)",
  origoLogoLightBlue: "hsla(205, 100%, 63%, 1)",
  origoLogoLightBlueHover: "hsl(205, 100%, 74%)",
}

const mobile = isUsingMobileDevice(window.navigator);

const base: BaseTheme = {
  name: ThemeNames.Dark,
  primaryFontFamily: "Inter",
  secondaryFontFamily: "Agency FB",
  scaledFontSizes: {
    xs: `calc(.5em + (8 - 4) * ((100vw - 1024px) / (2560 - 1024)))`,
    small: `calc(${
      mobile ? ".8125em" : "0.625em"
    } + (13 - 8) * ((100vw - 1024px) / (2560 - 1024)))`,
    medium: "calc(.8125em + (16 - 13) * ((100vw - 1024px) / (2560 - 1024)))",
    mediumLarge:
      "calc(.8125em + (20 - 13) * ((100vw - 1024px) / (2560 - 1024)))",
    large: `calc(${
      mobile ? "2em" : "1em"
    } + (32 - 16) * ((100vw - 1024px) / (2560 - 1024)))`,
    xl: "calc(2em + (48 - 32) * ((100vw - 1024px) / (2560 - 1024)))",
  },
  constantFontSizes: {
    xs: ".5rem",
    small: ".8125rem",
    medium: "1rem",
    mediumLarge: "1.3rem",
    large: "2rem",
    xl: "3rem",
  },
  numberSizes: {
    xs: ".7rem",
    small: "1rem",
    medium: "1.5rem",
    large: "2rem",
    xl: "3rem",
  },
  borderRadius: {
    light: "4px",
    medium: "8px",
    heavy: "12px",
  },
  spacing: {
    extraLight: "0.1875rem",
    light: ".25rem",
    medium: ".5rem",
    heavy: "1rem",
    extraHeavy: "1.5rem",
  },
  gridColumnGap: "1.5em",
  getHSLAValues: hslaValues,
  scrollBarWidth: "0.8em",
  mediaQueries: {
    minHeight: "1000px",
    maxHeight: "800px",
    minWidth: "1440px",
    maxWidth: "1920px",
  },
  shadow: new Shadow("0 4px 6px", "hsla(0, 0%, 0%, 0.1)"),
};

const dark: DefaultTheme = {
  ...base,
  colors: {
    alarmDetailsColour: {
      alarm1ListTile: new ColorSet(colorPalette.darkAlarm1Lighter),
      alarm1Hover: new ColorSet(colorPalette.darkAlarm1Lighter),
      alarm2ListTile: new ColorSet(colorPalette.darkAlarm2Lighter),
      alarm2Hover: new ColorSet(colorPalette.darkAlarm2Lighter),
      alarm3ListTile: new ColorSet(colorPalette.darkAlarm3Lighter),
      alarm3Hover: new ColorSet(colorPalette.darkAlarm3Lighter),
      alarm4Hover: new ColorSet(colorPalette.darkAlarm4Lighter),
      alarmSelected: new ColorSet(colorPalette.darkGray3),
    },
    bodyBackgroundColor: colorPalette.darkBodyBackground,
    mainColor: new ColorSet(colorPalette.dark2),
    textColor: new ColorSet(colorPalette.darkGray1),
    textInputBackgroundColor: colorPalette.darkGray1,
    tooltipBackgroundColor: colorPalette.dark2,
    cardBackgroundHover: colorPalette.dark5,
    componentBackgroundColor: new ColorSet(colorPalette.dark1),
    componentBackgroundActive: new ColorSet(colorPalette.dark6),
    textColorInverse: new ColorSet(colorPalette.darkBodyBackground),
    warning: colorPalette.darkAlarm1,
    signal: colorPalette.darkAttention1,
    signalHover: colorPalette.darkAttention1Lighter,
    attention: colorPalette.darkAlarm2,
    ready: colorPalette.darkAttention2,
    mainColorInverse: new ColorSet(colorPalette.dark2),
    gray: {
      light: new ColorSet(colorPalette.darkGray2),
      dark: new ColorSet(colorPalette.darkGray3),
    },
    errorCardBackgroundColor: colorPalette.darkGray3,
    iconColor: colorPalette.darkGray1,
    reportChartColor: colorPalette.dark5,
    headerBackgroundColor: colorPalette.darkTopMenu,
    headerDropdownColor: colorPalette.dark1,
    headerDropdownBackgroundColor: colorPalette.dark1,
    headerDropdownBackgroundHoverColor: colorPalette.darkTopMenu, 
    headerDropdownSelectedItemTextColor: colorPalette.dark2,
    headerDropdownTextColor: colorPalette.dark2,
    headerTitleColor: colorPalette.darkGray3,
    headerButtonIconColor: colorPalette.dark2,
    origoLogoColor: colorPalette.origoLogoLightBlue,
    origoLogoHoverColor: colorPalette.origoLogoLightBlueHover,
    origoLogoColorSecondary: colorPalette.origoLogoWhite,
    productionUnitAlarmlistTimeStampColorDark: colorPalette.darkAttention3,
    productionUnitAlarmlistTimeStampColorLight: colorPalette.darkGray2,
    productionUnitAlarmlistTitleColor: colorPalette.darkGray1,
    productionUnitMarkerCircleBackground: colorPalette.dark6,
    productionUnitMarkerInfoBackground: colorPalette.darkBodyBackground,
    productionUnitMarkerInfoPillBackground: colorPalette.dark6,
    productionUnitMarkerProgressbarFill: colorPalette.dark2,
    buttonColors: {
      primaryButtonColor: colorPalette.dark4,
      primaryButtonHoverColor: colorPalette.dark2,
      primaryButtonTextColor: new ColorSet(colorPalette.darkGray1),
      secondaryButtonColor: colorPalette.dark6,
      secondaryButtonHoverColor: colorPalette.dark5,
      secondaryButtonTextColor: new ColorSet(colorPalette.darkGray2),
      tertiaryButtonColor: colorPalette.dark1,
      tertiaryButtonHoverColor: colorPalette.dark5,
      radioButtonBackground: colorPalette.dark1,
      radioButtonBorder: colorPalette.dark2,
      radioButtonCheckedIndicator: colorPalette.darkGray1,
    },
  },
  shadow: new Shadow("0 4px 6px", "hsla(0, 0%, 0%, 0.1)"),
};

const light: DefaultTheme = {
  ...base,
  name: ThemeNames.Light,
  colors: {
    alarmDetailsColour: {
      alarm1ListTile: new ColorSet(colorPalette.lightAlarm1Lighter),
      alarm1Hover: new ColorSet(colorPalette.lightAlarm1Lighter),
      alarm2ListTile: new ColorSet(colorPalette.lightAlarm2Lighter),
      alarm2Hover: new ColorSet(colorPalette.lightAlarm2Lighter),
      alarm3ListTile: new ColorSet(colorPalette.lightAlarm3Lighter),
      alarm3Hover: new ColorSet(colorPalette.lightAlarm3Lighter),
      alarm4Hover: new ColorSet(colorPalette.lightAlarm4Lighter),
      alarmSelected: new ColorSet(colorPalette.lightGray2),
    },
    bodyBackgroundColor: colorPalette.lightBodyBackground,
    mainColor: new ColorSet(colorPalette.light2),
    textColor: new ColorSet(colorPalette.lightGray1),
    textInputBackgroundColor: colorPalette.light1,
    tooltipBackgroundColor: colorPalette.light6,
    cardBackgroundHover: colorPalette.light4,
    componentBackgroundColor: new ColorSet(colorPalette.light1),
    componentBackgroundActive: new ColorSet(colorPalette.light6),
    textColorInverse: new ColorSet(colorPalette.lightGray1),
    warning: colorPalette.lightAlarm1,
    signal: colorPalette.lightAttention1,
    signalHover: colorPalette.lightAttention1Lighter,
    attention: colorPalette.lightAlarm2,
    ready: colorPalette.lightAttention2,
    mainColorInverse: new ColorSet(colorPalette.light2),
    gray: {
      light: new ColorSet(colorPalette.lightGray2),
      dark: new ColorSet(colorPalette.lightGray3),
    },
    errorCardBackgroundColor: colorPalette.lightGray3,
    iconColor: colorPalette.light1,
    reportChartColor: colorPalette.light5,
    headerBackgroundColor: colorPalette.lightTopMenu,
    headerDropdownColor: colorPalette.light5,
    headerDropdownBackgroundColor: colorPalette.light6,
    headerDropdownBackgroundHoverColor: colorPalette.lightBodyBackground,
    headerDropdownTextColor: colorPalette.light3,
    headerDropdownSelectedItemTextColor: colorPalette.light3,
    headerTitleColor: colorPalette.light3,
    headerButtonIconColor: colorPalette.light3,
    origoLogoColor: colorPalette.origoLogoDarkBlue,
    origoLogoHoverColor: colorPalette.origoLogoDarkBlueHover,
    origoLogoColorSecondary: colorPalette.origoLogoGrey2,
    productionUnitAlarmlistTimeStampColorDark: colorPalette.lightGray2,
    productionUnitAlarmlistTimeStampColorLight: colorPalette.light1,
    productionUnitAlarmlistTitleColor: colorPalette.light1,
    productionUnitMarkerCircleBackground: colorPalette.light1,
    productionUnitMarkerInfoBackground: colorPalette.lightBodyBackground,
    productionUnitMarkerInfoPillBackground: colorPalette.light4,
    productionUnitMarkerProgressbarFill: colorPalette.light2,
    buttonColors: {
      primaryButtonColor: colorPalette.light2,
      primaryButtonHoverColor: colorPalette.light5,
      primaryButtonTextColor: new ColorSet(colorPalette.light1),
      secondaryButtonColor: colorPalette.light4,
      secondaryButtonHoverColor: colorPalette.light6,
      secondaryButtonTextColor: new ColorSet(colorPalette.light1),
      tertiaryButtonColor: colorPalette.light5,
      tertiaryButtonHoverColor: colorPalette.light2,
      radioButtonBackground: colorPalette.light1,
      radioButtonBorder: colorPalette.light6,
      radioButtonCheckedIndicator: colorPalette.light2,
    },
  },
  shadow: new Shadow("0 4px 6px", "hsla(0, 0%, 0%, 0.1)"),
};

light.colors.temperatureColors = getTemperatureColors(light.colors);
// Append alarm colors for themes

dark.colors.alarmPriorities = {
  "1": {
    Acknowledged: {
      background: "transparent",
      hover: dark.colors.alarmDetailsColour.alarm1Hover.quinary,
      alarmFontColorOne: dark.colors.textColor.secondary,
      priorityTileBackgroundColor: dark.colors.alarmDetailsColour.alarm1ListTile.secondary,
      priorityTileFontColor:
        colorPalette.darkGray1,     
      checkedIcon: dark.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.darkAlarm1,
      hover: dark.colors.alarmDetailsColour.alarm1Hover.primary,
      inactiveAlarmBackground: dark.colors.gray.dark.quinary,
      selected: dark.colors.alarmDetailsColour.alarmSelected.secondary,
      alarmFontColorOne: colorPalette.darkGray1,
      priorityTileBackgroundColor: colorPalette.darkAlarm1,
      priorityTileFontColor: colorPalette.darkGray1,
      checkedIcon: colorPalette.darkBodyBackground,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
  },
  "2": {
    Acknowledged: {
      background: "transparent",
      hover: dark.colors.alarmDetailsColour.alarm2Hover.quinary,
      alarmFontColorOne: dark.colors.textColor.secondary,  
      priorityTileBackgroundColor: dark.colors.alarmDetailsColour.alarm2ListTile.secondary,
      priorityTileFontColor:
      colorPalette.dark1,      
      checkedIcon: dark.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.darkAlarm2,
      hover: dark.colors.alarmDetailsColour.alarm2Hover.primary, 
      inactiveAlarmBackground: dark.colors.gray.dark.quinary,
      selected: dark.colors.alarmDetailsColour.alarmSelected.secondary,     
      alarmFontColorOne: colorPalette.dark1,
      alarmFontColorTwo: "aquamarine",      
      priorityTileBackgroundColor:colorPalette.darkAlarm2,
      priorityTileFontColor: colorPalette.dark1,
      checkedIcon: colorPalette.darkBodyBackground,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
  },
  "3": {
    Acknowledged: {
      background: "transparent",
      hover: dark.colors.alarmDetailsColour.alarm3Hover.background, 
      alarmFontColorOne: dark.colors.textColor.secondary,
      alarmFontColorTwo: dark.colors.textColor.secondary,
      priorityTileBackgroundColor:
      dark.colors.alarmDetailsColour.alarm3ListTile.secondary,
      priorityTileFontColor: colorPalette.darkGray1,
      checkedIcon: dark.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.darkAlarm3,
      hover: dark.colors.alarmDetailsColour.alarm3Hover.primary, 
      inactiveAlarmBackground: dark.colors.gray.dark.quinary,
      selected: dark.colors.alarmDetailsColour.alarmSelected.secondary,
      alarmFontColorOne: colorPalette.darkGray1,
      alarmFontColorTwo: colorPalette.darkGray1,
      priorityTileBackgroundColor: colorPalette.darkAlarm3,
      priorityTileFontColor: colorPalette.darkGray1,
      checkedIcon: colorPalette.darkBodyBackground,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
    Supressed: {
      background: "transparent",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
  },
  "4": {
    Acknowledged: {
      background: "transparent",
      hover: dark.colors.alarmDetailsColour.alarm4Hover.secondary,
      alarmFontColorOne: dark.colors.textColor.primary,
      alarmFontColorTwo: dark.colors.textColor.secondary,      
      priorityTileBackgroundColor: colorPalette.darkAlarm4,
      priorityTileFontColor:
        dark.colors.buttonColors.primaryButtonTextColor.primary,
      checkedIcon: dark.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.darkAlarm4,
      hover: dark.colors.alarmDetailsColour.alarm4Hover.primary,
      inactiveAlarmBackground: dark.colors.gray.dark.quinary,
      selected: dark.colors.alarmDetailsColour.alarmSelected.secondary,      
      alarmFontColorOne: dark.colors.textColor.primary,
      alarmFontColorTwo: dark.colors.textColor.primary,
      priorityTileBackgroundColor: colorPalette.darkAlarm4,
      priorityTileFontColor: colorPalette.darkGray1,
      checkedIcon: colorPalette.darkBodyBackground,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: dark.colors.textColor.secondary,
    },
  },

};

light.colors.alarmPriorities = {
  "1": {
    Acknowledged: {
      background: "transparent",
      hover: light.colors.alarmDetailsColour.alarm1Hover.quinary,
      alarmFontColorOne: light.colors.textColor.secondary,
      priorityTileBackgroundColor: colorPalette.lightAlarm1,
      priorityTileFontColor: colorPalette.light1,      
      checkedIcon: light.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.lightAlarm1,
      hover: light.colors.alarmDetailsColour.alarm1Hover.primary, 
      inactiveAlarmBackground: light.colors.textColorInverse.quinary,
      selected: light.colors.alarmDetailsColour.alarmSelected.primary,
      alarmFontColorOne: colorPalette.light1,
      priorityTileBackgroundColor: colorPalette.lightAlarm1,
      priorityTileFontColor: colorPalette.light1,
      checkedIcon: colorPalette.light1,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
  },
  "2": {
    Acknowledged: {
      background: "transparent",
      hover: light.colors.alarmDetailsColour.alarm2Hover.quinary,
      alarmFontColorOne: light.colors.textColor.secondary,      
      priorityTileBackgroundColor: colorPalette.lightAlarm2,
      priorityTileFontColor: colorPalette.lightGray1,
      checkedIcon: light.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.lightAlarm2,
      hover: light.colors.alarmDetailsColour.alarm2Hover.primary,
      inactiveAlarmBackground: light.colors.textColorInverse.quinary,
      selected: light.colors.alarmDetailsColour.alarmSelected.primary,
      alarmFontColorOne: colorPalette.lightGray1,
      priorityTileBackgroundColor: colorPalette.lightAlarm2,
      priorityTileFontColor: colorPalette.lightGray1,    
      checkedIcon: colorPalette.light1,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
  },
  "3": {
    Acknowledged: {
      background: "transparent",
      hover: light.colors.alarmDetailsColour.alarm3Hover.quinary,
      alarmFontColorOne: light.colors.textColor.secondary,
      alarmFontColorTwo: light.colors.textColor.secondary,      
      priorityTileBackgroundColor: colorPalette.lightAlarm3,
      priorityTileFontColor: colorPalette.light1,     
      checkedIcon: light.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.lightAlarm3,
      inactiveAlarmBackground: light.colors.textColorInverse.quinary,
      selected: light.colors.alarmDetailsColour.alarmSelected.primary,
      hover: light.colors.alarmDetailsColour.alarm3Hover.primary,
      alarmFontColorOne: colorPalette.light1,
      alarmFontColorTwo: colorPalette.lightGray1,
      priorityTileBackgroundColor: colorPalette.lightAlarm3,
      priorityTileFontColor: colorPalette.light1,
      checkedIcon: colorPalette.light1,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
  },
  "4": {
    Acknowledged: {
      background: "transparent",
      hover: light.colors.alarmDetailsColour.alarm4Hover.background,
      alarmFontColorOne: light.colors.textColor.secondary,
      alarmFontColorTwo: light.colors.textColor.secondary,
      priorityTileBackgroundColor: colorPalette.lightAlarm4,
      priorityTileFontColor: colorPalette.lightGray1,
      checkedIcon: light.colors.textColor.secondary,
    },
    NotAcknowledged: {
      background: colorPalette.lightAlarm4,
      inactiveAlarmBackground: light.colors.textColorInverse.quinary,
      hover: light.colors.alarmDetailsColour.alarm4Hover.primary,
      selected: light.colors.alarmDetailsColour.alarmSelected.primary,
      alarmFontColorOne: colorPalette.lightGray1,
      alarmFontColorTwo: colorPalette.lightGray1,
      priorityTileBackgroundColor: colorPalette.lightAlarm4,
      priorityTileFontColor: colorPalette.lightGray1,
      checkedIcon: colorPalette.light1,
    },
    Passive: {
      background: "transparent",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
    Supressed: {
      background: "#333",
      alarmFontColorOne: light.colors.textColor.secondary,
    },
  },
};

dark.colors.temperatureColors = getTemperatureColors(dark.colors);

export { dark, light };

